<template>
  <div>
  <div class="w-full justify-center ">
        <div class="vx-row w-full justify-center ">
          <div div class="vx-row w-full justify-center">
            <vs-input
              v-validate="'required|email'"
              data-vv-validate-on="blur"
              name="email"
              class="xl:w-5/12 sm:w-1/4 m-3 mt-3"
              icon-no-border
              label-placeholder="Email"
              :placeholder="$t('FullName')"
              v-model="email"
              :icon-after="true"
            />
          </div>
          <div div class="vx-row w-full justify-center">
            <vs-input
              v-validate="'required|email'"
              data-vv-validate-on="blur"
              name="email"
              class="xl:w-5/12 sm:w-1/4 m-3 mt-3"
              icon-no-border
              label-placeholder="Email"
              :placeholder="$t('Title')"
              v-model="email"
              :icon-after="true"
            />
          </div>
          <div div class="vx-row w-full justify-center">
            <vs-input
              v-validate="'required|email'"
              data-vv-validate-on="blur"
              name="email"
              class="xl:w-5/12 sm:w-1/4 m-3 mt-3"
              icon-no-border
              label-placeholder="Email"
              :placeholder="$t('Locallicensenumber')"
              v-model="email"
              :icon-after="true"
            />
          </div>
          <div class="w-full m-2">
            <h1>
              <p>{{ $t("Additionalinformation") }}</p>
            </h1>
          </div>
          <div class="vx-row w-full justify-center">
            <vs-input
              v-validate="'required|email'"
              data-vv-validate-on="blur"
              name="email"
              class="xl:w-5/12 sm:w-1/4 m-3 mt-3"
              icon-no-border
              label-placeholder="Email"
              :placeholder="$t('Addcertificates')"
              v-model="email"
              :icon-after="true"
            />
          </div>
          <div class="vx-row w-full justify-center">
            <h5>
              <p class="m-2">{{ $t("GraduationDate") }}</p>
            </h5>
            <!-- <flat-pickr v-model="date" name="email" /> -->
            <datepicker placeholder="Select Date" v-model="date"></datepicker>

            <vs-icon
              icon-pack="feather"
              icon="icon-calendar"
              class="m-1"
              size="medium"
            />
          </div>

          <div div class="vx-row w-full justify-left">
            <p class="text-align-left">{{ $t(BiographyOptional) }}</p>
          </div>

          <div div class="vx-row w-full justify-center">
            <vs-textarea
              v-model="textarea"
              label="Type your biography here"
              width="420px"
            />
          </div>

          <div div class="vx-row w-full justify-center">
            <vs-input
              v-validate="'required|email'"
              data-vv-validate-on="blur"
              name="Speciality"
              class="xl:w-5/12 sm:w-1/4 m-3 mt-3"
              icon-no-border
              label-placeholder="Email"
              :placeholder="$t('Speciality')"
              v-model="email"
              :icon-after="true"
            />
          </div>
          <div div class="vx-row w-full justify-center">
            <v-select
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
              name="email"
              class="w-full sm:w-1/4  xl:w-2/2  m-2 mt-2"
              :placeholder="$t('Country')"
              :icon-after="true"
            />
            <v-select
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
              name="email"
              class="w-full sm:w-1/4  xl:w-2/2  m-2 mt-2"
              :placeholder="$t('Province')"
              :icon-after="true"
            />
            <v-select
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
              name="email"
              class="w-full sm:w-1/4  xl:w-2/2  m-2 mt-2"
              :placeholder="$t('City')"
              :icon-after="true"
            />
          </div>
          <div class=" w-full ">
            <div class="justify-right ">
              <vs-button
                color="rgb(62, 201, 214)  "
                type="filled"
                style="float: right;"
                >{{ $t("Save") }}</vs-button
              >
            </div>
          </div>
        </div>
      </div>
  </div>
</template>
<script>
// import point from "../../components/point.vue";

import Datepicker from "vuejs-datepicker";

export default {
  components: {
    // point
  },
  data() {
    return {
      options: [],
      radios1: "luis",
      date: null
    };
  },
   props:{
        patientModel:{
            type:Object,
            required:true
        },
        
    },
  components: {
    Datepicker
  },

  methods: {
    successUpload() {
      this.$vs.notify({
        color: "success",
        title: "Upload Success",
        text: "Lorem ipsum dolor sit amet, consectetur"
      });
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        var reader = new FileReader();
        reader.onload = e => {
          this.dataImg = e.target.result;
        };
        reader.readAsDataURL(input.target.files[0]);
      }
    }
  }
};
</script>
<style>

</style>
